import React, { useState, useEffect, useRef } from 'react';
import { Button, Container, Typography, Box, Card, CardContent, CardActions, Grid, Link as MuiLink, Divider, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Link } from 'react-router-dom';
// import { GoogleLogin } from '@react-oauth/google';  // Commented out Google Auth
import ChatBubble from './ChatBubble';
import LoginForm from './Login';
import SignupForm from './Signup';

import { useAuth } from '../hooks/useAuth';

import routes from '../routes';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
 * PricingSection Component
 * Displays the pricing plans for the application
 * Rendered at the bottom of the landing page
 */

const PricingSection = () => {
  const pricingPlans = [
    { 
      title: 'Free',
      price: 'Free',

      features: [
        'First 50 messages free',
        'Basic mental health support',
        'Access to essential features'
      ]
    },
    { 
      title: 'Pro Monthly', 
      price: '$15/month', 
      features: [
        'Unlimited messages',
        'Advanced mental health insights',
        'Priority support',
        'Personalized recommendations',
        'Progress tracking'
      ]
    },
    { 
      title: 'Pro Yearly', 
      price: '$120/year', 
      features: [
        'Everything in Pro Monthly',
        'Save $60 annually',
        'Premium support',
        'Early access to new features'
      ]
    },
  ];

  return (
    <Box py={8} bgcolor="#f1f0e8">
      <Container maxWidth="lg">
        <Typography variant="h4" align="center" gutterBottom>
          Choose Your Plan
        </Typography>
        <Typography variant="subtitle1" align="center" paragraph>
          Start with our free plan or upgrade for unlimited access
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {pricingPlans.map((plan, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h2" gutterBottom>
                    {plan.title}
                  </Typography>
                  <Typography variant="h4" color="primary" gutterBottom>
                    {plan.price}
                  </Typography>
                  {plan.features.map((feature, idx) => (
                    <Typography key={idx} variant="body1" paragraph>
                       {feature}
                    </Typography>
                  ))}
                </CardContent>
                <CardActions>
                  <Button fullWidth variant="contained" style={{ backgroundColor: '#c65e41' }}>
                    {plan.price === '0' ? 'Start Free' : 'Choose Plan'}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};


/**
 * FAQSection Component
 * Displays the frequently asked questions for the application
 * Rendered at the bottom of the landing page
 */
const FAQSection = () => {
  const faqs = [
    {
      question: "What is Ayna?",
      answer: "Ayna is an AI-powered mental health platform that provides accessible, confidential support through conversational therapy. Our AI therapist is designed to help you explore your thoughts, feelings, and challenges in a safe, judgment-free environment."
    },
    {
      question: "Is my conversation with Ayna private?",
      answer: (
        <>
          Yes, absolutely. We take your privacy seriously. All conversations are encrypted and confidential. Your personal information is protected according to the highest security standards and our <MuiLink href={routes.privacyPolicy} sx={{ color: '#c65e41' }}>privacy policy</MuiLink>.
        </>
      )
    },
    {
      question: "How does AI therapy work?",
      answer: "Ayna uses advanced AI technology to engage in therapeutic conversations. It understands context, emotions, and provides responses based on established therapeutic approaches. While it's not a replacement for human therapists, it offers immediate, 24/7 support for mental wellness."
    },
    {
      question: "Can I use Ayna in a crisis?",
      answer: "While Ayna provides valuable support, it's not designed for crisis intervention. If you're experiencing a mental health emergency, please contact emergency services or crisis helplines immediately. We can provide resources for crisis support."
    },
    {
      question: "What types of issues can Ayna help with?",
      answer: "Ayna can help with various concerns including anxiety, stress, mild depression, relationship issues, work-life balance, and personal growth. However, for severe mental health conditions, we recommend seeking professional human therapy."
    }
  ];

  return (
    <Box sx={{ 
      py: 8, 
      px: { xs: 2, md: 8 },
      backgroundColor: '#f5f4ef'
    }}>
      <Container maxWidth="lg">
        <Typography 
          variant="h3" 
          align="center" 
          gutterBottom
          sx={{ 
            color: '#3C392B',
            mb: 6,
            fontSize: { xs: '2.5rem', md: '3.5rem' }
          }}
        >
          Frequently Asked Questions
        </Typography>
        
        {faqs.map((faq, index) => (
          <Accordion 
            key={index}
            sx={{
              mb: 2,
              backgroundColor: '#f1f0e8',
              boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
              '&:before': {
                display: 'none',
              },
              '&.Mui-expanded': {
                margin: '8px 0',
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#c65e41' }} />}
              sx={{
                '&.Mui-expanded': {
                  minHeight: '48px',
                },
                '.MuiAccordionSummary-content.Mui-expanded': {
                  margin: '12px 0',
                }
              }}
            >
              <Typography 
                sx={{ 
                  fontWeight: 500,
                  color: '#3C392B',
                  fontSize: { xs: '1rem', md: '1.2rem' }
                }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  color: '#776566',
                  fontSize: { xs: '0.9rem', md: '1.1rem' },
                  lineHeight: 1.6
                }}
              >
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Box>
  );
};

/**
 * LandingPage Component
 * Main landing page of the application
 * @param {Object} props
 * @param {Function} props.onLogin - Callback function to handle successful login
 */
const LandingPage = ({ onLogin }) => {
  // State for managing chat bubble animations
  const [visibleBubbles, setVisibleBubbles] = useState([false, false, false, false, false, false, false]);
  // State for controlling auth form visibility
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [hasAnimationStarted, setHasAnimationStarted] = useState(false);
  const chatSectionRef = useRef(null);

  /**
   * Effect hook to handle chat bubble animations
   * Shows bubbles sequentially with delays
   */
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && !hasAnimationStarted) {
          setHasAnimationStarted(true);
          // Reduced delays between messages
          const timeouts = [
            setTimeout(() => setVisibleBubbles(prev => [true, prev[1], prev[2], prev[3], prev[4], prev[5], prev[6]]), 1),
            setTimeout(() => setVisibleBubbles(prev => [prev[0], true, prev[2], prev[3], prev[4], prev[5], prev[6]]), 1100),
            setTimeout(() => setVisibleBubbles(prev => [prev[0], prev[1], true, prev[3], prev[4], prev[5], prev[6]]), 2100),
            setTimeout(() => setVisibleBubbles(prev => [prev[0], prev[1], prev[2], true, prev[4], prev[5], prev[6]]), 3100),
            setTimeout(() => setVisibleBubbles(prev => [prev[0], prev[1], prev[2], prev[3], true, prev[5], prev[6]]), 4100),
            setTimeout(() => setVisibleBubbles(prev => [prev[0], prev[1], prev[2], prev[3], prev[4], true, prev[6]]), 5100),
            setTimeout(() => setVisibleBubbles(prev => [prev[0], prev[1], prev[2], prev[3], prev[4], prev[5], true]), 6100),
          ];

          return () => timeouts.forEach(clearTimeout);
        }
      },
      { threshold: 0.2 }
    );

    if (chatSectionRef.current) {
      observer.observe(chatSectionRef.current);
    }

    return () => {
      if (chatSectionRef.current) {
        observer.unobserve(chatSectionRef.current);
      }
    };
  }, [hasAnimationStarted]);

  /* Google Sign-in functionality temporarily disabled
  const handleGoogleSuccess = (credentialResponse) => {
    console.log(credentialResponse);
  };

  const handleGoogleError = () => {
    console.log('Google Sign-In Failed');
  };
  */

  const renderAuthButtons = () => (
    <Box mt={4} bgcolor="#f1f0e8" p={5} borderRadius={3} sx={{
      transform: { xs: 'scale(1)', md: 'scale(1.3)' },
      transformOrigin: 'top center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: { xs: '100%', md: 'auto' }
    }}>
      <Box display="flex" justifyContent="center">
        {/* Sign Up Button */}
        <Button 
          variant="contained" 
          size="large"
          onClick={() => setShowSignup(true)}
          style={{ 
            marginRight: '20px', 
            backgroundColor: '#c65e41',
            fontSize: '1.4rem',
            padding: '15px 40px',
          }}
        >
          <Typography variant="button" sx={{ fontWeight: 'bold' }}>
            Sign Up
          </Typography>
        </Button>

        {/* Login Button */}
        <Button 
          variant="outlined" 
          color="secondary"
          size="large"
          onClick={() => setShowLogin(true)}
          style={{ 
            fontSize: '1.4rem',
            padding: '15px 40px',
          }}
        >
          <Typography variant="button" sx={{ fontWeight: 'bold' }}>
            Log In
          </Typography>
        </Button>
      </Box>

      {/* Google Sign-in Section temporarily disabled 
      <Box mt={3}>
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={handleGoogleError}
          useOneTap
          render={renderProps => (
            <Button 
              onClick={renderProps.onClick} 
              disabled={renderProps.disabled}
              style={{
                fontSize: '1.4rem',
                padding: '15px 40px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#f1f0e8',
                border: '2px solid #c65e41',
              }}
            >
              <Typography variant="button" sx={{ fontWeight: 'bold', color: '#c65e41' }}>
                Sign in with Google
              </Typography>
            </Button>
          )}
        />
      </Box>
      */}

      <Typography variant="caption" align="center" mt={2} sx={{ 
        display: 'block', 
        maxWidth: '300px', 
        color: '#776566' 
      }}>
        By continuing, you agree to Ayna's{' '}
        <MuiLink href={routes.terms} target="_blank" rel="noopener" sx={{ color: '#776566', fontWeight: 'bold' }}>
          Terms and Conditions
        </MuiLink>{' '}
        and acknowledge our{' '}
        <MuiLink href={routes.privacyPolicy} target="_blank" rel="noopener" sx={{ color: '#776566', fontWeight: 'bold' }}>
          Privacy Policy
        </MuiLink>
        .
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ 
      backgroundColor: '#F5F4EF',
      margin: 0,
      padding: 0,
      width: '100%',
      overflowX: 'hidden'  // This prevents horizontal scroll
    }}>
      {/* Main content container */}
      <Box 
        sx={{ 
          display: 'flex',
          minHeight: '100vh',
          flexDirection: { xs: 'column', md: 'row' },
          margin: 0,
          padding: 0,
          width: '100%'
        }}
      >
        {/* Left Section - Auth Forms */}
        <Box 
          flex={1} 
          display="flex" 
          flexDirection="column" 
          justifyContent="flex-start" 
          alignItems="center" 
          sx={{
            pt: { xs: 4, md: 10 },
            px: { xs: 1, md: 10 },
            width: '100%'
          }}
        >
          {/* Logo and Title Section */}
          <Box display="flex" alignItems="center" mb={4}>
            <img 
              src="/logo_bg_removed.png" 
              alt="Ayna Logo" 
              style={{ 
                marginRight: '20px', 
                maxWidth: '100px' 
              }} 
            />
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#3C392B' }}>
              Ayna
            </Typography>
          </Box>

          {/* Main Heading and Subtitle */}
          <Typography 
            variant="h3" 
            gutterBottom 
            sx={{ 
              color: '#3C392B',
              // Responsive font sizes based on screen breakpoints:
              // xs (extra small): 3.7rem for mobile phones (<600px)
              // sm (small): 6rem for tablets (600px+) 
              // md (medium): 6rem for desktop (900px+)
              fontSize: { xs: '3.6rem', sm: '5rem', md: '6.4rem' },
              textAlign: { xs: 'center', md: 'center' },
              mb: { xs: 2, md: 3 }
            }}
          >
            <Box component="span" display={{ xs: 'block', md: 'inline' }}>Your AI </Box>
            <Box component="span" display={{ xs: 'block', md: 'inline' }}>Therapist</Box>
          </Typography>
          <Typography variant="subtitle1" gutterBottom sx={{ 
            color: '#3C392B',
            fontSize: { 
              xs: '1.3rem', // Mobile
              sm: '1.4rem', // Tablet
              md: '1.6rem'  // Desktop
            }
          }}>
            Let's start our journey of self-exploration
          </Typography>

          {/* Auth Forms Toggle */}
          {showLogin ? (
            <LoginForm 
              onBack={() => setShowLogin(false)}
             
            />
          ) : showSignup ? (
            <SignupForm 
              onBack={() => setShowSignup(false)}
              // onSignupSuccess={onLogin}
            />
          ) : (
            renderAuthButtons()
          )}
        </Box>

        {/* Right Section - Chat Demo */}
        <Box 
          ref={chatSectionRef}
          flex={1} 
          display="flex" 
          flexDirection="column" 
          justifyContent="flex-start" 
          alignItems="center" 
          sx={{
            minHeight: { xs: '60vh', md: '100vh' },
            width: '100%',
            backgroundColor: '#f5f5f5',
            borderRadius: { xs: 0, md: 4 },
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            margin: { xs: 0, md: 2 },
            mt: { xs: 4, md: 0 },
            pt: { md: 3 }
          }}
        >
          {/* Chat Bubbles Container */}
          <Box 
            sx={{
              width: '100%',
              maxWidth: '900px',
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              overflowY: 'auto',
              padding: 3,
            }}
          >
            {/* Demo Conversation - AI Message */}
            <ChatBubble 
              text="I feel lonely and life is too much. My friends are busy and I don't know what to do." 
              isUser={true} 
              isVisible={visibleBubbles[0]} 
              sx={{
                p: 3,
                mb: 2,
                '& .MuiTypography-root': {
                  fontSize: '1.5rem',
                },
                maxWidth: '70%',
                borderRadius: 4,
              }}
            />
            {/* Demo Conversation - User Message */}
            <ChatBubble 
              text="why do you think your friends are busy?" 
              isUser={false} 
              isVisible={visibleBubbles[1]} 
              sx={{
                p: 3,
                mb: 2,
                '& .MuiTypography-root': {
                  fontSize: '1.5rem',
                },
                maxWidth: '70%',
                alignSelf: 'flex-end',
                borderRadius: 4,
              }}
            />
            {/* Demo Conversation - AI Response */}
            <ChatBubble 
              text="they are building their startups. what hurts is emotional relations are no longer a priority for them. I miss those moments when we just hangout" 
              isUser={true} 
              isVisible={visibleBubbles[2]} 
              sx={{
                p: 3,
                mb: 2,
                '& .MuiTypography-root': {
                  fontSize: '1.5rem',
                },
                maxWidth: '70%',
                borderRadius: 4,
              }}
            />
            {/* Demo Conversation - User Response */}
            <ChatBubble 
              text="seems to me you mean emotional life should not be ignored in pursuit of professional success. And I am guessing that Derek is also busy with his exams like you mentioned last time." 
              isUser={false} 
              isVisible={visibleBubbles[3]} 
              sx={{
                p: 3,
                mb: 2,
                '& .MuiTypography-root': {
                  fontSize: '1.5rem',
                },
                maxWidth: '70%',
                alignSelf: 'flex-end',
                borderRadius: 4,
              }}
            />
            <ChatBubble 
              text="Yes, exactly." 
              isUser={true} 
              isVisible={visibleBubbles[5]} 
              sx={{
                p: 3,
                mb: 2,
                '& .MuiTypography-root': {
                  fontSize: '1.5rem',
                },
                maxWidth: '70%',
                alignSelf: 'flex-end',
                borderRadius: 4,
              }}
            />
            <ChatBubble 
              text="Aren't you close to Susan, your project manager? I know she is your colleague but you do spend a lot of time together right?" 
              isUser={false} 
              isVisible={visibleBubbles[6]} 
              sx={{
                p: 3,
                mb: 2,
                '& .MuiTypography-root': {
                  fontSize: '1.5rem',
                },
                maxWidth: '70%',
                borderRadius: 4,
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* Add FAQ Section here, before the footer */}
      <FAQSection />

      {/* New Footer Section */}
      <Box 
        component="footer" 
        sx={{
          backgroundColor: '#f1f0e8',
          py: 4,
          px: 2,
          mt: 4,
          borderTop: '1px solid #e0e0e0'
        }}
      >
        <Container maxWidth="lg">
          <Box 
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-around',
              alignItems: { xs: 'center', md: 'flex-start' },
              gap: 3
            }}
          >
            <Box>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                Contact Email
              </Typography>
              <MuiLink href="mailto:shubham@ayna.co.in" sx={{ color: '#776566' }}>
                shubham@ayna.co.in
              </MuiLink>
            </Box>

            <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'block' } }} />

            <Box>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                Contact Number
              </Typography>
              <MuiLink href="tel:+918867180971" sx={{ color: '#776566' }}>
                +91 8867180971
              </MuiLink>
            </Box>

            <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'block' } }} />

            <Box>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                Addresses
              </Typography>
              <Box>
                <Typography variant="subtitle2" color="textPrimary" gutterBottom>
                  Registered Address:
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'left', mb: 2 }}>
                  324, Gayatri Nagar A, Maharani Farm,<br />
                  Durgapura, Jaipur, Rajasthan, 302018
                </Typography>
                
                <Typography variant="subtitle2" color="textPrimary" gutterBottom>
                  Operating Address:
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'left' }}>
                  NO.21 1ST A CROSS 1ST MAIN<br />
                  ASHWATNAGAR EXTENSION RMV II STAGE,<br />
                  Bengaluru, Karnataka, 560094
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default LandingPage;
