import React, { useState } from 'react';
import { Box, Container, Typography, Grid, Card, CardContent, CardActions, Switch, FormControlLabel, Button } from '@mui/material';

const Pricing = () => {
  const [isUSD, setIsUSD] = useState(true);

  const convertToINR = (usdPrice) => {
    const conversionRate = 75; // Example conversion rate
    return `₹${(parseFloat(usdPrice.replace('$', '')) * conversionRate).toFixed(2)}`;
  };

  const pricingPlans = [
    { 
      title: 'Free',
      price: 'Free',
      features: [
        'First 50 messages free',
        'Basic mental health support',
        'Access to essential features'
      ]
    },
    { 
      title: 'Pro Monthly', 
      price: '$15/month', 
      features: [
        'Unlimited messages',
        'Advanced mental health insights',
        'Priority support',
        'Personalized recommendations',
        'Progress tracking'
      ]
    },
    { 
      title: 'Pro Yearly', 
      price: '$120/year', 
      features: [
        'Everything in Pro Monthly',
        'Save $60 annually',
        'Premium support',
        'Early access to new features'
      ]
    },
  ];

  return (
    <Box py={8} bgcolor="#f1f0e8">
      <Container maxWidth="lg">
        <Typography variant="h4" align="center" gutterBottom>
          Choose Your Plan
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
          <FormControlLabel
            control={
              <Switch
                checked={isUSD}
                onChange={() => setIsUSD(!isUSD)}
                color="default"
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#c65e41',
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#c65e41',
                  },
                }}
              />
            }
            label={isUSD ? 'USD' : 'INR'}
            labelPlacement="start"
            sx={{
              color: '#3C392B',
              fontSize: '1.2rem',
            }}
          />
        </Box>
        <Grid container spacing={4} justifyContent="center">
          {pricingPlans.map((plan, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h2" gutterBottom>
                    {plan.title}
                  </Typography>
                  <Typography variant="h4" color="primary" gutterBottom>
                    {plan.price === 'Free' ? 'Free' : (isUSD ? plan.price : convertToINR(plan.price))}
                  </Typography>
                  {plan.features.map((feature, idx) => (
                    <Typography key={idx} variant="body1" paragraph>
                       {feature}
                    </Typography>
                  ))}
                </CardContent>
                <CardActions>
                  <Button fullWidth variant="contained" style={{ backgroundColor: '#c65e41' }}>
                    {plan.price === 'Free' ? 'Start Free' : 'Choose Plan'}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Pricing; 