import React from 'react';
import { Container, Typography, Box, Avatar, Paper } from '@mui/material';

const About = () => {
  return (
    <Box sx={{ 
      backgroundColor: '#F5F4EF',
      minHeight: '100vh',
      py: 8
    }}>
      <Container maxWidth="lg">
        {/* Mission Section */}
        <Box mb={8}>
          <Typography 
            variant="h2" 
            gutterBottom
            sx={{ 
              color: '#3C392B',
              textAlign: 'center',
              mb: 4,
              fontSize: { xs: '2.5rem', md: '3.5rem' }
            }}
          >
            Our Mission
          </Typography>
          <Typography 
            variant="h5" 
            sx={{ 
              color: '#776566',
              textAlign: 'center',
              maxWidth: '800px',
              mx: 'auto',
              lineHeight: 1.8
            }}
          >
            To make mental health support accessible, immediate, and personalized through the power of AI, 
            ensuring everyone has a companion to talk to in their moments of need.
          </Typography>
        </Box>

        {/* Business Description Section */}
        <Box mb={8}>
          <Typography 
            variant="h2" 
            gutterBottom
            sx={{ 
              color: '#3C392B',
              textAlign: 'center',
              mb: 4,
              fontSize: { xs: '2.5rem', md: '3.5rem' }
            }}
          >
            What We Do
          </Typography>
          <Paper 
            elevation={3}
            sx={{
              maxWidth: '800px',
              mx: 'auto',
              p: 4,
              backgroundColor: '#f1f0e8',
              borderRadius: 4
            }}
          >
            <Typography 
              variant="body1"
              sx={{ 
                color: '#776566',
                lineHeight: 1.8,
                mb: 3
              }}
            >
              Ayna is pioneering the future of mental health support through advanced artificial intelligence. 
              Our AI-powered therapeutic companion provides immediate, personalized conversations that help users 
              navigate their emotional challenges, stress, anxiety, and daily mental health needs.
            </Typography>
            
            <Typography 
              variant="body1"
              sx={{ 
                color: '#776566',
                lineHeight: 1.8,
                mb: 3
              }}
            >
              Unlike traditional chatbots, Ayna's AI is built on sophisticated natural language processing and 
              emotional intelligence algorithms, allowing it to understand context, remember previous conversations, 
              and provide responses that are both empathetic and therapeutically sound. Our system continuously 
              learns and adapts to each user's unique needs, creating a truly personalized mental health companion.
            </Typography>

            <Typography 
              variant="body1"
              sx={{ 
                color: '#776566',
                lineHeight: 1.8
              }}
            >
              While we don't replace traditional therapy, we serve as a valuable complement to existing mental 
              health services. Our platform is particularly effective for those seeking immediate support, 
              those in between therapy sessions, or individuals who might be taking their first steps toward 
              mental health care. We provide 24/7 accessibility, complete privacy, and a judgment-free space 
              for users to express themselves and receive guidance based on established therapeutic principles.
            </Typography>
          </Paper>
        </Box>

        {/* Team Section */}
        <Box>
          <Typography 
            variant="h2" 
            gutterBottom
            sx={{ 
              color: '#3C392B',
              textAlign: 'center',
              mb: 6,
              fontSize: { xs: '2.5rem', md: '3.5rem' }
            }}
          >
            About the Founder
          </Typography>

          {/* Founder Card */}
          <Paper 
            elevation={3}
            sx={{
              maxWidth: '800px',
              mx: 'auto',
              p: 4,
              backgroundColor: '#f1f0e8',
              borderRadius: 4
            }}
          >
            <Box 
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
                gap: 4
              }}
            >
              <Avatar
                src="/selfie.jpg"  // Updated path to reference image from public folder
                sx={{
                  width: 200,
                  height: 200,
                  border: '4px solid #c65e41'
                }}
              />
              <Box>
                <Typography 
                  variant="h4" 
                  gutterBottom
                  sx={{ color: '#3C392B' }}
                >
                  Shubham Jain
                </Typography>
                <Typography 
                  variant="h6" 
                  gutterBottom
                  sx={{ color: '#c65e41' }}
                >
                  Founder
                </Typography>
                <Typography 
                  variant="body1"
                  sx={{ 
                    color: '#776566',
                    lineHeight: 1.8,
                    mb: 2
                  }}
                >
                  With 8 years of experience in AI and Neuroscience, Shubham brings a unique perspective to mental health technology. 
                  A graduate of BITS Pilani, he has dedicated his career to understanding the intersection of human cognition and artificial intelligence.
                </Typography>
                <Typography 
                  variant="body1"
                  sx={{ 
                    color: '#776566',
                    lineHeight: 1.8
                  }}
                >
                  His background in neuroscience and expertise in AI development has been instrumental in creating Ayna's 
                  sophisticated therapeutic AI that understands and responds to human emotions with empathy and intelligence.
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default About; 